import { first } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { CalendarDatePipe } from '@app/pipes/calendar-date.pipe';
import { TranslitPipe } from '@app/pipes/translit.pipe';
import { TagCoordinates, TagGroup, TagsService } from '@services/tags.service';
import { UtilsService } from '@services/utils.service';

@Component({
  selector: 'app-serbian-map',
  standalone: true,
  templateUrl: './serbian-map.component.html',
  styleUrls: ['./serbian-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatMenuModule, MatIconModule,
    MatButtonModule, CalendarDatePipe, TranslitPipe,],
})
export class SerbianMapComponent {
  @Input() citySlug!: string | null;
  @Input() smallMap = false;
  @Input() biggerMap = false;

  private tagsService = inject(TagsService);
  private cdr = inject(ChangeDetectorRef);
  private router = inject(Router);

  public zoomLevel = 1;
  public selectedZoom = false;
  public selectedText = false;
  public singleSize = '';
  public cities: TagCoordinates[] = [];
  public lastCity: string | null = null;

  ngOnInit() {
    this.getItems();
  }

  onSwitchText() {
    this.selectedText = !this.selectedText;
  }

  onSwitchZoom() {
    this.selectedZoom = !this.selectedZoom;
    this.zoomLevel = this.selectedZoom ? 2 : 1;
  }

  getItems() {
    this.tagsService.getItems()
      .pipe(first())
      .subscribe({
        next: (response) => {
          const cityTags = response.filter(t => t.group === TagGroup.cities);
          this.cities = this.tagsService.setCityCoordinates(cityTags, this.citySlug);

          if (this.citySlug) {
            this.singleSize = 'size-2';
          }

          setTimeout(() => {
            this.cdr.detectChanges();
          }, 1);
        }
      });
  }

  onTag(slug: string | null) {
    this.router.navigateByUrl(`tag/${slug}/podaci`);
  }

  onCityLogo(slug: string | null) {
    this.router.navigateByUrl(`tag/${slug}/podaci`);
  }

  onTagContext(slug: string | null) {
    const url = `tag/${slug}/licnosti`;
    UtilsService.openOnMiddleClick(url);
  }

  goToTag(path: string) {
    const url = `tag/${path}`;
    this.router.navigateByUrl(url);
  }

  onLastCity(city: TagCoordinates) {
    this.lastCity = city.name;

    setTimeout(() => {
      this.lastCity = null;
      this.cdr.detectChanges();
    }, 500);
  }

};
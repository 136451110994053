<div class="serbian-map-container"
  [ngClass]="{'bigger': biggerMap, 'small': smallMap}"
  (pinchout)="zoomLevel = 2"
  (pinchin)="zoomLevel = 1"
  [class.doubleZoomContainer]="zoomLevel === 2">

  <div class="map-holder"
    [class.doubleZoomLevel]="zoomLevel === 2">


    @if(!citySlug) {
    <div class="button-group"
      [class.zoomed]="zoomLevel === 2">
      <button class="switch"
        (click)="onSwitchText()"
        [class.switch-active]="selectedText">
        Aa
      </button>

      <button class="switch portrait-screen-only"
        (click)="onSwitchZoom()"
        [class.switch-active]="selectedZoom">
        2x
      </button>
    </div>
    }

    <img class="serbia-map"
      loading="lazy"
      src="assets/images/mapa_serbia.png"
      alt="mapa_serbia">

    <!-- External Cities -->
    @if (selectedText && !citySlug) {
    <div class="external-country bosnia"
      (click)="goToTag('prostor-bosne')">
      {{'Босна' | translit}}
    </div>
    <div class="external-country croatia"
      (click)="goToTag('prostor-hrvatske')">
      {{'Хрватска' | translit}}
    </div>
    <div class="external-country montenegro"
      (click)="goToTag('prostor-crne-gore')">
      {{'Црна Гора' | translit}}
    </div>
    <div class="external-country albania"
      (click)="goToTag('prostor-albanije')">
      {{'Албанија' | translit}}
    </div>
    <div class="external-country macedonia"
      (click)="goToTag('prostor-makedonije')">
      {{'Македонија' | translit}}
    </div>
    <div class="external-country bulgary"
      (click)="goToTag('prostor-bugarske')">
      {{'Бугарска' | translit}}
    </div>
    <div class="external-country romania"
      (click)="goToTag('prostor-rumunije')">
      {{'Румунија' | translit}}
    </div>
    <div class="external-country hungary"
      (click)="goToTag('prostor-madjarske')">
      {{'Мађарска' | translit}}
    </div>
    }

    <!-- Serbian Cities -->
    @for(city of cities; track city.name; let i = $index) {
    @defer (on viewport; on timer(1s)) {
    <div class="city-placeholder"
      [ngClass]="singleSize || city.size"
      [ngStyle]="{'background':  city.color}"
      [style.top]="city.top"
      [style.left]="city.left"
      [style.border-color]="city.color"
      [class.invisible]="selectedText "
      (mouseenter)="onLastCity(city)"
      [matMenuTriggerFor]="singleSize ? null : aboveMenu">

      @if(selectedText) {
      <div class="name-tag"
        [style.color]="city.color">{{city.name | translit}}</div>
      } @else {
      @if (lastCity === city.name) {
      <span class="last-city"
        [style.color]="city.color">{{lastCity | translit}}</span>
      }
      }
    </div>

    <mat-menu #aboveMenu="matMenu"
      [xPosition]="city.moveLeft ? 'before': 'after'"
      yPosition="above">
      <div class="city-stats">

        @if (city.imagePath) {
        <div (click)="onCityLogo(city.slug)"
          class="city-image-wrapper">
          <img class="city-logo"
            loading="lazy"
            [src]="city.imagePath"
            [alt]="city.name | translit">
        </div>
        }

        <div class="city-name">
          {{city.name | translit}}
          @if (city.licencePlate) {
          <span class="licence-plate">
            ({{city.licencePlate | translit}})
          </span>
          }
        </div>

        @if (city.region) {
        <div class="region">
          <mat-icon>extension</mat-icon>
          <span>{{city.region | translit}}
            @if (city.region !== 'Град Београд' && city.region.length < 15)
              {
              {{'округ'
              |
              translit}}
              }
              </span>
        </div>
        }

        @if (city.population) {
        <div class="citizen">
          <mat-icon>groups</mat-icon>
          <span>{{city.population | translit}}</span>
        </div>
        }

        @if (city.cityDate) {
        <div class="citizen">
          <mat-icon>event</mat-icon>
          <span>{{city.cityDate | calendarDate | translit}}</span>
        </div>
        }

        @if (city.postNumber) {
        <div class="height">
          <mat-icon>local_post_office</mat-icon>
          <span>
            {{city.callPrefix | translit}}
            <span class="post-number">({{city.postNumber | translit}})</span>
          </span>
        </div>
        }

      </div>
      <button class="menu-button"
        mat-menu-item
        [style.color]="city.color"
        (click)="onTag(city.slug)"
        (contextmenu)="onTagContext(city.slug)">{{'ОТВОРИ' | translit}}</button>
    </mat-menu>
    } @placeholder (minimum 100ms) {
    <br />
    }
    }


  </div>
</div>